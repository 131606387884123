import { NullTemplateVisitor } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, of, from } from 'rxjs';
import { switchMap, map, mapTo, tap, catchError } from 'rxjs/operators';
import { SelectProductVariantPopupComponent } from 'src/app/components/general/select-product-variant-popup/select-product-variant-popup.component';
import { TicketParametersComponent } from 'src/app/components/general/ticket-parameters/ticket-parameters.component';
import { Money, Product } from 'src/app/lib/lib';
import { ProductValidationInfo } from 'src/app/lib/product/product-validation-info';
import { ProductWithVariants } from 'src/app/lib/product/product-with-variants';
import { NumberScanModalResult } from 'src/app/lib/scan/number-scan-modal-result';
import { TicketParameters } from 'src/app/lib/ticket/ticket-parameters';
import { BarcodeScanModalComponent } from 'src/app/modules/barcode/components/barcode-scan-modal/barcode-scan-modal.component';
import { CardDispenserReadCardModalComponent } from 'src/app/modules/card-dispenser/components/card-dispenser-read-card-modal/card-dispenser-read-card-modal.component';
import { MessageModalService } from 'src/app/modules/message-modal/services/message-modal.service';
import { CardInformation } from 'src/app/modules/recharge-card/models/card-information';
import { RfidCardScanModalComponent } from 'src/app/modules/rfid-card/components/rfid-card-scan-modal/rfid-card-scan-modal.component';
import { CardDispenserService } from '../card-dispenser/card-dispenser.service';
import { ConfigurationService } from '../configuration/configuration.service';
import { ModalService } from '../gui/modal/modal-service';
import { LoggingService } from '../logging/logging.service';
import { VuCommunicationService } from '../vu/vu-communication.service';

@Injectable()
export class ProductValidatorService {

  private modalRef: BsModalRef;

  constructor(
    private modalService: ModalService,
    private log: LoggingService,
    private messageModalService: MessageModalService,
    private vuCommunicationService: VuCommunicationService,
    private cardDispenserService: CardDispenserService,
    private configurationService: ConfigurationService,
  ) {
  }

  modalForceClose(): void {
    this.modalService.close(this.modalRef);
    this.modalRef = null;
  }

  get validationInProgress(): boolean {
    return this.modalRef != null;
  }

  validateProduct(product: Product): Observable<ProductValidationInfo> {
    return of(true).pipe(
      switchMap(() => {
        if (product.scanCardRequestedToCheckIn || product.insertCardRequestedToCheckIn) {
          return this._validateCheckInProduct(product);
        }

        return this._validateProduct(product);
      }),
      catchError(error => {
        return this.showErrorMessageAndReturnGoBackResult(error);
      }),
    );
  }

  _validateCheckInProduct(product: Product): Observable<ProductValidationInfo> {
    return of(true).pipe(
      switchMap(() => {
        if (!product || this.modalRef) {
          return of(null);
        }
        return this.showCardScanModal(product, true, product.insertCardRequestedToCheckIn);
      }),
      switchMap((numberScanModalResult: NumberScanModalResult) => {
        if (numberScanModalResult == null) {
          return of(null);
        }

        if (!numberScanModalResult) {
          return of(ProductValidationInfo.createGoBack());
        }

        const barcode = numberScanModalResult.scannedNumber;
        const cardInformation = numberScanModalResult.data as CardInformation;

        if (!barcode || !cardInformation) {
          return of(ProductValidationInfo.createGoBack());
        }

        if (!cardInformation.lastTicketProductId || cardInformation.lastTicketProductId === 0) {
          this.log.info(`SaleService. Last ticket product not found for card '${barcode}'.`);
          return this.showErrorMessageAndReturnGoBackResult('Check-in product did not found for this card');
        }

        if (cardInformation.checkinErrorMessage) {
          this.log.info(`SaleService. Check in error message '${cardInformation.checkinErrorMessage}' for card '${barcode}'.`);
          return this.showErrorMessageAndReturnGoBackResult(cardInformation.checkinErrorMessage);
        }

        const productValidationInfo = new ProductValidationInfo();
        productValidationInfo.cardInformation = cardInformation;
        productValidationInfo.barcode = barcode;

        return this.fillCheckInAdditionalProducts(productValidationInfo, cardInformation.lastTicketProductId, barcode);
      }),
      switchMap((productValidationInfo: ProductValidationInfo) => {
        if (!productValidationInfo || productValidationInfo.goBack
          || (productValidationInfo.additionalProducts
            && productValidationInfo.additionalProducts.length !== 0)) {
          return of(productValidationInfo);
        }

        return this.showErrorMessageAndReturnGoBackResult('No check-in products are available for this card');
      }),
    );
  }

  fillCheckInAdditionalProducts(productValidationInfo: ProductValidationInfo, checkInProductId: number, barcode: string)
    : Observable<ProductValidationInfo> {
    return from(this.vuCommunicationService.vuHttp.getProduct(checkInProductId)).pipe(
      switchMap(product => {
        if (!product) {
          this.log.info(`SaleService. getProduct. Last ticket product not found for card '${barcode}'.`);
          return this.showErrorMessageAndReturnGoBackResult('Check-in product did not found for this card');
        }

        if (!product.additionalProductIds || product.additionalProductIds.length === 0) {
          return this.showErrorMessageAndReturnGoBackResult('No check-in products are available for this card');
        }

        return this.fillAdditionalProducts(product, productValidationInfo).pipe(
          switchMap((_productValidationInfo: ProductValidationInfo) => {
            return this.fillVariantAdditionalProducts(product, _productValidationInfo);
          }),
        );
      }),
    );
  }

  getProductAdditionalProducts(product: Product): Observable<Product[]> {
    return of(true).pipe(
      switchMap(() => {
        if (!product || !product.additionalProductIds || product.additionalProductIds.length === 0) {
          return of(null);
        }
        return from(this.vuCommunicationService.vuHttp.getProductsByIds(product.additionalProductIds));
      }),
    );
  }

  showErrorMessageAndReturnGoBackResult(message: string): Observable<ProductValidationInfo> {
    return this.messageModalService.showErrorObservable(message)
      .pipe(mapTo(ProductValidationInfo.createGoBack()));
  }

  showCardScanModal(product: Product, rfidCard: boolean, useCardDispenser = false): Observable<NumberScanModalResult> {
    return new Observable(subscriber => {
      this.modalRef = this.modalService.show(
        useCardDispenser ? CardDispenserReadCardModalComponent : rfidCard ? RfidCardScanModalComponent : BarcodeScanModalComponent,
        {
          product,
        },
        (result: NumberScanModalResult) => {
          this.modalRef = null;
          subscriber.next(result);
          subscriber.complete();
        });
    });
  }

  _validateProduct(product: Product): Observable<ProductValidationInfo> {
    return of(true).pipe(
      map(() => {
        if (!product) {
          return null;
        }

        const productValidationInfo = new ProductValidationInfo();
        productValidationInfo.product = product;
        return productValidationInfo;
      }),
      switchMap((productValidationInfo: ProductValidationInfo) => {
        return this.fillAdditionalProducts(product, productValidationInfo);
      }),
      switchMap((productValidationInfo: ProductValidationInfo) => {
        return this.cardDispenserValidate(product, productValidationInfo);
      }),
      switchMap((productValidationInfo: ProductValidationInfo) => {
        return this.scanCardOrBarcodeValidate(product, productValidationInfo);
      }),
      switchMap((productValidationInfo: ProductValidationInfo) => {
        return this.fillVariantAdditionalProducts(product, productValidationInfo);
      }),
      switchMap((productValidationInfo: ProductValidationInfo) => {
        return this.ticketParametersValidate(product, productValidationInfo);
      }),
      switchMap((productValidationInfo: ProductValidationInfo) => {
        return this.giftCardValidate(product, productValidationInfo);
      }),
    );
  }

  giftCardValidate(product: Product, productValidationInfo: ProductValidationInfo): Observable<ProductValidationInfo> {
    return of(true).pipe(
      switchMap(() => {
        if (!product || !productValidationInfo || productValidationInfo.goBack || !product.rfidCard) {
          return of(productValidationInfo);
        }

        const paymentControllerUrl = this.configurationService.configuration.odooUrl + '/api/gift-card/recharge-product';
        const requestValues = new Map<string, any>();
        requestValues.set('productId', product.id);

        return from(this.vuCommunicationService.ExternalApiService.sendPostRequestOdooJson(paymentControllerUrl, requestValues))
          .pipe(
            catchError(error => {
              return of(productValidationInfo);
            }),
            switchMap(result => {
              if (!result || !result.recharge_product_id || !result.recharge_price) {
                return of(productValidationInfo);
              }
              return from(this.vuCommunicationService.vuHttp.getProductsByIds([result.recharge_product_id]))
                .pipe(
                  map(products => {
                    if (products && products.length === 1) {
                      const rechargeProduct = products[0];
                      rechargeProduct.customPrice = new Money(result.recharge_price,
                        rechargeProduct.price ? rechargeProduct.price.currencyCode : 'EUR');
                    }
                    this.addSelectedProductsToProductValidationInfo(productValidationInfo, products);
                    return productValidationInfo;
                  }),
                );
            }),
          );
      }),
    );
  }

  fillAdditionalProducts(product: Product, productValidationInfo: ProductValidationInfo): Observable<ProductValidationInfo> {
    return of(true).pipe(
      switchMap(() => {
        if (!product || !productValidationInfo || productValidationInfo.goBack) {
          return of(productValidationInfo);
        }

        return this.getProductAdditionalProducts(product).pipe(
          map(products => {
            if (products) {
              const excludedProductIds = productValidationInfo?.cardInformation?.excludedProductIds || [];
              if (excludedProductIds) {
                products = products.filter((item) => !excludedProductIds.includes(item.id));
              }
            }
            return products;
          }),
          map(products => {
            productValidationInfo.additionalProducts = products;
            return productValidationInfo;
          }),
        );
      }),
    );
  }

  fillVariantAdditionalProducts(product: Product, productValidationInfo: ProductValidationInfo): Observable<ProductValidationInfo> {
    return of(true).pipe(
      switchMap(() => {
        if (!product || !productValidationInfo || productValidationInfo.goBack || !product.additionalProductsVariants) {
          return of(productValidationInfo);
        }

        let productsWithVariants = ProductWithVariants.createFromAnyArray(product.additionalProductsVariants);
        if (!productsWithVariants || productsWithVariants.length === 0) {
          return of(productValidationInfo);
        }

        const excludedProductTemplateIds = productValidationInfo?.cardInformation?.excludedProductTemplateIds || [];
        if (excludedProductTemplateIds && excludedProductTemplateIds.length) {
          productsWithVariants = productsWithVariants.filter(item => !excludedProductTemplateIds.includes(item.productTmplId));
          if (!productsWithVariants || productsWithVariants.length === 0) {
            return of(productValidationInfo);
          }
        }

        if (productsWithVariants && productsWithVariants.length === 1 && productsWithVariants[0].variants.length <= 1) {
          if (productsWithVariants[0].variants.length === 1) {
            return from(this.vuCommunicationService.vuHttp.getProductsByIds([productsWithVariants[0].variants[0].productId]))
              .pipe(
                map(products => {
                  this.addSelectedProductsToProductValidationInfo(productValidationInfo, products);
                  return productValidationInfo;
                }),
              );
          }
          return of(productValidationInfo);
        }

        return this.showSelectProductVariantModal(productsWithVariants).pipe(
          switchMap((result) => {
            const accepted = result?.accepted;
            if (!accepted) {
              productValidationInfo.goBack = true;
            } else {
              const selectedProducts = result?.selectedProducts;
              this.addSelectedProductsToProductValidationInfo(productValidationInfo, selectedProducts);
            }

            return of(productValidationInfo);
          }),
        );
      }),
    );
  }

  addSelectedProductsToProductValidationInfo(productValidationInfo, selectedProducts): void {
    if (selectedProducts && selectedProducts.length) {
      if (productValidationInfo.additionalProducts) {
        productValidationInfo.additionalProducts.push.apply(productValidationInfo.additionalProducts, selectedProducts);
      } else {
        productValidationInfo.additionalProducts = selectedProducts;
      }
    }
  }

  showSelectProductVariantModal(productsWithVariants: ProductWithVariants[]): Observable<any> {
    return new Observable(subscriber => {
      this.modalRef = this.modalService.show(
        SelectProductVariantPopupComponent,
        {
          productsWithVariants,
        },
        (result) => {
          this.modalRef = null;
          subscriber.next(result);
          subscriber.complete();
        });
    });
  }

  cardDispenserValidate(product: Product, productValidationInfo: ProductValidationInfo): Observable<ProductValidationInfo> {
    return of(true).pipe(
      switchMap(() => {
        const checkCardInCardDispenser = (product && product.isCard) ||
          (productValidationInfo &&
            productValidationInfo.additionalProducts &&
            productValidationInfo.additionalProducts.some(item => item.isCard));

        if (!productValidationInfo || productValidationInfo.goBack || !checkCardInCardDispenser) {
          return of(productValidationInfo);
        }

        return this.cardDispenserService.status.pipe(
          switchMap(
            status => {
              if (!status || !status.isAvailable) {
                return this.showErrorMessageAndReturnGoBackResult('Card dispenser not available');
              }
              if (status.stackEmpty) {
                return this.showErrorMessageAndReturnGoBackResult('There are no RFID card to produce');
              }
              return of(productValidationInfo);
            }
          ),
        );
      }),
    );
  }

  scanCardOrBarcodeValidate(product: Product, productValidationInfo: ProductValidationInfo): Observable<ProductValidationInfo> {
    return of(true).pipe(
      switchMap(() => {
        const scanRequested = product && (product.scanCardRequested || product.scanBarcodeRequested);

        if (!productValidationInfo || productValidationInfo.goBack || !scanRequested) {
          return of(productValidationInfo);
        }

        return this.showCardScanModal(product, product.scanCardRequested).pipe(
          map((numberScanModalResult: NumberScanModalResult) => {
            if (numberScanModalResult && numberScanModalResult.scannedNumber) {
              productValidationInfo.cardInformation = numberScanModalResult.data as CardInformation;
              productValidationInfo.barcode = numberScanModalResult.scannedNumber;
            } else {
              productValidationInfo.goBack = true;
            }
            return productValidationInfo;
          }),
        );
      }),
    );
  }

  ticketParametersValidate(product: Product, productValidationInfo: ProductValidationInfo): Observable<ProductValidationInfo> {
    return of(true).pipe(
      switchMap(() => {

        if (productValidationInfo && product) {
          productValidationInfo.ticketParameters = new TicketParameters();
          productValidationInfo.ticketParameters.groupSize = product.groupSizeMin;
        }

        if (!productValidationInfo || productValidationInfo.goBack || !product || !product.ticketParametersRequested) {
          return of(productValidationInfo);
        }

        return this.showTicketParametersModal(product, productValidationInfo.ticketParameters).pipe(
          switchMap((accepted: boolean) => {
            if (!accepted) {
              productValidationInfo.goBack = true;
            }
            return of(productValidationInfo);
          }),
        );
      }),
    );
  }

  showTicketParametersModal(product: Product, ticketParameters: TicketParameters): Observable<boolean> {
    return new Observable(subscriber => {
      this.modalRef = this.modalService.show(
        TicketParametersComponent,
        {
          product,
          ticketParameters,
        },
        (accepted: boolean) => {
          this.modalRef = null;
          subscriber.next(accepted);
          subscriber.complete();
        });
    });
  }
}
