import { VuState, MessageView, NonOperationalState, Configuration } from '../../lib/lib';
import { MachineBaseService } from './machine-base.service';
import { NonOperationalService } from '../non-operational.service';
import { MessageType, Message } from '../message.service';
import { LiteTouchTileService } from '../lite/lite-touch-tile.service';
import { Injectable } from '@angular/core';

@Injectable()
export class MachineNonOperationalService extends MachineBaseService {
  private nonOperationalService: NonOperationalService;
  private isSubscribed = false;
  private nonOperationalState: NonOperationalState = new NonOperationalState();
  private liteTouchTileService: LiteTouchTileService;

  init(): void {
    super.init();
    this.nonOperationalService = this.injector.get(NonOperationalService);
    this.nonOperationalService.eventStateChanged.subscribe((x: NonOperationalState) => this.onNonOperationalStateChanged(x));
    this.liteTouchTileService = this.injector.get(LiteTouchTileService);
    this.dispatcherService.eventServiceInitialized.subscribe(() => this.eventServiceInitialized());
    this.dispatcherService.onVuStateChangedSubscribe(() => this.updateState());
  }

  private eventServiceInitialized(): void {
    this.dispatcherService.onConfigurationChangedSubscribe(x => this.onConfigurationChangedSubscribe(x));
  }

  get machineName(): string {
    return 'Machine Non Operational';
  }

  protected getTransitions(): any[] {
    return super.getTransitions(
      { name: 'toPending', from: 'off', to: 'pending' },
      { name: 'toEnabled', from: 'pending', to: 'enabled' },
      { name: 'toMaintenance', from: ['enabled', 'offline', 'noConnection', 'burglary', 'loading', 'autoSaleMode'], to: 'maintenance' },
      { name: 'toLoading', from: ['enabled', 'offline', 'noConnection', 'burglary', 'maintenance', 'autoSaleMode'], to: 'loading' },
      { name: 'toBurglary', from: ['enabled', 'maintenance', 'offline', 'noConnection', 'loading', 'autoSaleMode'], to: 'burglary' },
      { name: 'toOffline', from: ['enabled', 'maintenance', 'noConnection', 'burglary', 'loading', 'autoSaleMode'], to: 'offline' },
      { name: 'toAutoSaleMode', from: ['enabled', 'maintenance', 'noConnection', 'burglary', 'loading', 'offline'], to: 'autoSaleMode' },
      { name: 'toNoConnection', from: ['enabled', 'maintenance', 'offline', 'burglary', 'loading', 'autoSaleMode'], to: 'noConnection' },
      { name: 'toService', from: ['enabled', 'maintenance', 'off', 'noConnection', 'burglary', 'offline', 'loading', 'autoSaleMode'], to: 'service' },
      { name: 'toMultipleAccessError', from: ['enabled', 'maintenance', 'off', 'noConnection', 'burglary', 'offline', 'loading', 'autoSaleMode'], to: 'MultipleAccessError' },
    );
  }

  protected getMethods(): any {
    const scope = this;
    return super.getMethods({
      onToPending: () => {
        scope.eventPending.emit();
      },
      onToMaintenance: () => {
        scope.showMessageScreen('Maintenance Mode',
          `The device is in the maintenance mode. Sorry for the inconveniences.`,
          true);
        if (scope.additionalPropertiesConfigurationService.isLiteMode) {
          scope.liteTouchTileService.maintenanceMode();
        }
      },
      onToLoading: () => {
        scope.showMessageScreen('Loading..',
          ``,
          true);
        if (scope.additionalPropertiesConfigurationService.isLiteMode) {
          scope.liteTouchTileService.maintenanceMode();
        }
      },
      onToBurglary: () => {
        scope.showMessageScreen('Burglary',
          `Unauthorized door opening!`);
        if (scope.additionalPropertiesConfigurationService.isLiteMode) {
          scope.liteTouchTileService.maintenanceMode();
        }
      },
      onToOffline: () => {
        scope.showMessageScreen('Offline',
          `The device is offline`);
        if (scope.additionalPropertiesConfigurationService.isLiteMode) {
          scope.liteTouchTileService.maintenanceMode();
        }
      },
      onToAutoSaleMode: () => {
        scope.refreshAutoSaleModeMessage()
        if (scope.additionalPropertiesConfigurationService.isLiteMode) {
          scope.liteTouchTileService.maintenanceMode();
        }
      },
      onToNoConnection: () => {
        scope.showMessageScreen('No Connection',
          `There is connection to the device`);
        if (scope.additionalPropertiesConfigurationService.isLiteMode) {
          scope.liteTouchTileService.maintenanceMode();
        }
      },
      onToService: () => {
        // Leaves the application
        window.location.href = '/Service';
      },
      onToMultipleAccessError: () => {
        // Leaves the application
        window.location.href = '/MultipleAccessError';
      },
    });
  }

  onNonOperationalStateChanged(state: NonOperationalState): void {
    this.nonOperationalState = state;
    this.doAsync(() => this.updateState(), 'onNonOperationalStateChanged');
    if (state.vuState.isMaintenanceMode) {
      const text = this.buildText();
      this.log.info(`onVuStateChanged. text: ${text}.`);
      this.dispatcherService.messageViewText(text);
    }
  }

  private showMessageScreen(h1: string, h2: string, showText = false): void {
    const mv = new MessageView(h1, h2, showText, this.buildText());
    this.dispatcherService.messageViewUpdate(mv);
    this.router.navigateByUrl(this.additionalPropertiesConfigurationService.isLiteMode ? '/lite-mode/message' : '/message');
  }

  private buildText(): string {
    const state = this.nonOperationalState;
    const reasons = state.vuState.maintenaceModeReasons == null ? null : state.vuState.maintenaceModeReasons.join(', ');
    const violation = state.vuState.minHardwareConfigurationViolation;
    let result = reasons == null || reasons.length === 0 ? '' : `<div>${reasons}</div>`;
    result += violation == null || violation.length === 0 ? '' : `<div>${violation}</div>`;
    return result;
  }

  machineStart(): void {
    if (this.isInState('pending')) {
      this.machine.toEnabled();
      this.updateState();
    } else {
      this.log.warning(`machineStart not expected here. ${this.state}`);
    }
  }

  get isNonOperational(): boolean {
    return this.nonOperationalState.isNonOperational
      || !this.configurationService.configuration.changeDate;
  }

  protected onConfigurationChangedSubscribe(configuration: Configuration): void {
    this.updateState();
  }

  updateState(): void {
    if (!this.isNonOperational) {
      this.machine.toOff();
      return;
    }

    const vuState = this.nonOperationalState.vuState;
    const isConnected = this.nonOperationalState.isConnected;

    if (this.isInState('off')) {
      this.machine.toPending();
      return;
    } else if (this.isInState('pending')) {
      return;
    } else if (vuState.isServiceMode) {
      this.machine.toService();
    } else if (vuState.isMultipleAccessError) {
      this.machine.toMultipleAccessError();
    } else if (!isConnected) {
      if (!this.isInState('noConnection'))
        this.machine.toNoConnection();
    } else if (vuState.isLoadingMode) {
      this.machine.toLoading();
    } else if (vuState.isBurglaryMode && isConnected) {
      if (!this.isInState('burglary'))
        this.machine.toBurglary();
    } else if (vuState.isOfflineMode && isConnected) {
      if (!this.isInState('offline'))
        this.machine.toOffline();
    } else if ((vuState.isMaintenanceMode || !this.configurationService.configuration.changeDate) && isConnected) {
      if (!this.isInState('maintenance'))
        this.machine.toMaintenance();
    } else if (vuState.isAutoSaleMode && isConnected) {
      if (!this.isInState('autoSaleMode')) {
        this.machine.toAutoSaleMode();
      } else {
        this.refreshAutoSaleModeMessage();
      }
    }
  }

  private refreshAutoSaleModeMessage(): void {
    this.showMessageScreen('Auto Sale Mode', `The device is work in automatic mode`, true);
  }

  protected getMessages(): MessageType[] {
    return super.getMessages();
  }

  protected onMessage(message: Message): boolean {
    if (super.onMessage(message)) {
      return true;
    }

    switch (message.messageType) {
      default:
        break;
    }

    return false;
  }

  protected logMachine(event: any, message: string): void {
    if (event.from !== event.to) {
      super.logMachine(event, message);
    }
  }
}
